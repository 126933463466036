import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "115px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "65.21739130434783%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAACEElEQVQoz2OQ8E/k94iWCUxJbJtcPGVBaG23dGCyoGesuF+imG8CfsTA5x5tmlq64+T5jiXrYponpHVNK5o8XyM6hxj9DCLe8aev33n78fOmI6d3njy/7cTZmOYJHsVNkv5JhG0Ore2+cOvesp0H956+uPPEuUPnrqzce8SzpFktMlvYK04cv+aMnhn///8/d/Pu9hPn9py5tPPk+cMXr67ce0Q+JF3EO07cD6/mwKqO09dvC3rF8rpF8rpF8blFcbtGSgUkSwUki/rEE3C2pH9S2fRFimEZIj7xUgFJECTum0BQJ0izgEeMclimZ0mzYli6hH+iuB8IiYLlwGwEKYYQTIRwGaQCkiX8EwW9YuVC0gQ9YwU9YwU8YyT8EyX8kwQ8YkBcjxghL5C4pH+ShB9EECQO0qwTl5/aOc2juMmrtCWyoc8ht9Yxt9Y0tbRw0jzv0haf0taoxj7XwkaXggbXwoa0rul22dUBlR0+Za3C3nEMCqHp1pmVFunlwdVdniXNlhkVlunlWjG5roUNVpmVNplVLgX1BolF+omFWjG51pmVxsklJiml5mlloj7xDKoRWYFVHQ65tQGVHZENvTHNE+xzawKrOsLqun0r2oKqO8PresLre+yyqwU8oiFuFvAAIZCzJfyT5EPSpANTpPyTZINTlcIyFENBSD4kTSkMRMoFpyqGZcgHp8GDCh5gAMcRCd3pt8T+AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/ecf32ddbe1ea6ad84108c4a35c7be284/72348/federal_reserve_bank_of_philadelphia.webp 115w"],
            "sizes": "(max-width: 115px) 100vw, 115px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/ecf32ddbe1ea6ad84108c4a35c7be284/e907b/federal_reserve_bank_of_philadelphia.png 115w"],
            "sizes": "(max-width: 115px) 100vw, 115px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "https://cdn.payperks.com/payperks-corporate/1.16.1/static/ecf32ddbe1ea6ad84108c4a35c7be284/e907b/federal_reserve_bank_of_philadelphia.png",
            "alt": "Federal Reserve Bank of Philadelphia logo",
            "title": "Federal Reserve Bank of Philadelphia logo",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <p>{`While we’ve had the privilege of presenting in front of many esteemed audiences, we were particularly honored when the `}<a parentName="p" {...{
        "href": "http://www.phil.frb.org/consumer-credit-and-payments/payment-cards-center/"
      }}>{`Payment Cards Center at the Federal Reserve Bank of Philadelphia`}</a>{` asked us to present an hour and a half seminar on `}{`“`}{`Driving Positive Behavior Change through Education and Motivation`}{`”`}{`. The session is scheduled for later in May.`}</p>
    <p>{`Attendees will include members of the bank from functional areas including Economic Research, Supervision and Regulation, Community Development Studies and Education plus product folks from prepaid debit card issuers and influencers from the social impact realm.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "217px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "92.16589861751152%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAASABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAIDBAX/xAAWAQEBAQAAAAAAAAAAAAAAAAACAQP/2gAMAwEAAhADEAAAAYUX0oamYj0omOvAFn//xAAcEAACAgIDAAAAAAAAAAAAAAABAgARAxMhIjL/2gAIAQEAAQUCyCmHB1JOlmDOUgVYyrb+v//EABgRAAIDAAAAAAAAAAAAAAAAAAABAhAS/9oACAEDAQE/AVEzf//EABgRAAIDAAAAAAAAAAAAAAAAAAABAhAS/9oACAECAQE/AXM3f//EAB4QAAIBAwUAAAAAAAAAAAAAAAABEBEygQIhIjFB/9oACAEBAAY/AvMReb6kdplKLJah8VH/xAAcEAEAAgMBAQEAAAAAAAAAAAABABEhMVFBcbH/2gAIAQEAAT8hNFyYtkd8loINci22uRW2GdGmGVb4mN+EtGjWI315P//aAAwDAQACAAMAAAAQ39+9/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAEREDH/2gAIAQMBAT8QRChcz//EABoRAAEFAQAAAAAAAAAAAAAAAAABEBEhMVH/2gAIAQIBAT8QsI9F1v/EABwQAQADAQEAAwAAAAAAAAAAAAEAESExQWGRof/aAAgBAQABPxC7ztXMtygG8QHlYu27NWi0BtgKdDp+nYroz4KR+/pw5hRquZ5PUce/E//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/c13d0875c4cb80ccad7d52e9f0cc7c3d/25688/3c_philidelphia2.webp 217w"],
            "sizes": "(max-width: 217px) 100vw, 217px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/c13d0875c4cb80ccad7d52e9f0cc7c3d/6876a/3c_philidelphia2.jpg 217w"],
            "sizes": "(max-width: 217px) 100vw, 217px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "https://cdn.payperks.com/payperks-corporate/1.16.1/static/c13d0875c4cb80ccad7d52e9f0cc7c3d/6876a/3c_philidelphia2.jpg",
            "alt": "Philadelphia",
            "title": "Philadelphia",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <p>{`Jake and I will be discussing:`}</p>
    <ul>
      <li parentName="ul">{`PayPerks’ approach to behavior change`}</li>
      <li parentName="ul">{`The results from our pilot with MasterCard`}</li>
      <li parentName="ul">{`How current and potential cardholders experience PayPerks`}</li>
      <li parentName="ul">{`What applications exist for PayPerks outside of prepaid`}</li>
    </ul>
    <p>{`Following the seminar, the Payments Card Center will be posting our materials online.`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.facebook.com/payperks"
      }}>{`Like us on Facebook`}</a>{` if you want to be alerted when those materials are posted.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      